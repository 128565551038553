import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { ResponseDto } from "../../../models/response-dto.model";
import { Paginate } from "../../../models/pagination.model";
import { Tenant } from "../models/tenant.model";
import { Role } from "../../../../features/authentication/enums/role.enum";
import { IUser } from "../../../../features/authentication/models/user.model";
import { TenantLicense } from "../models/tenant-license.model";
import { Vertical } from "../models/vertical.model";
import { LicenseTemplate, LicenseTemplateComplete } from "../models/license-template.model";
import { CreateAdminPayload } from "../models/create-admin.payload";

@Injectable({
  providedIn: 'root',
})
export class PlatformManagerService {
  private readonly http = inject(HttpClient);

  getTenants(
    page: number,
    pageSize: number,
  ): Observable<Paginate<Tenant>> {
    let params = new HttpParams();
    params = params.set('page', page);
    params = params.set('pageSize', pageSize);

    return this.http
      .get<ResponseDto<Paginate<Tenant>>>(`/management-tenants`, { params })
      .pipe(map((response) => response.data));
  }

  loadTenant(tenant: string): Observable<Tenant> {
    return this.http
      .get<ResponseDto<Tenant>>(`/management-tenants/` + tenant, {})
      .pipe(map((response) => response.data));
  }

  getTenantUsers(
    page: number,
    pageSize: number,
    tenant?: string,
    role?: Role
  ): Observable<Paginate<IUser>> {
    let params = new HttpParams();
    params = params.set("page", page);
    params = params.set("pageSize", pageSize);

    if (tenant) {
      params = params.set("tenant", tenant);
    }

    if (role) {
      params = params.set("roleName", role);
    }

    return this.http
      .get<ResponseDto<Paginate<IUser>>>(`/management-users`, { params })
      .pipe(map((response) => response.data));
  }

  getTenantLicense(
    tenant: string,
  ): Observable<TenantLicense> {
    let params = new HttpParams().set("tenant", tenant);

    return this.http
      .get<ResponseDto<TenantLicense>>(`/tenant-license`, { params })
      .pipe(map((response) => response.data));
  }

  getVerticals(
    page: number,
    pageSize: number,
  ): Observable<Paginate<Vertical>> {
    let params = new HttpParams();
    params.set("page", page);
    params.set("pageSize", pageSize);

    return this.http
      .get<ResponseDto<Paginate<Vertical>>>(`/management-verticals`, { params })
      .pipe(map((response) => response.data));
  }

  getVertical(
    vertical: string
  ): Observable<Vertical> {
    let params = new HttpParams();

    return this.http
      .get<ResponseDto<Vertical>>(`/management-verticals/`+ vertical, { params })
      .pipe(map((response) => response.data));
  }

  getLicenseTemplates(
    page: number,
    pageSize: number,
  ): Observable<Paginate<LicenseTemplate>> {
    let params = new HttpParams();
    params.set("page", page);
    params.set("pageSize", pageSize);

    return this.http
      .get<ResponseDto<Paginate<LicenseTemplate>>>(`/template-license`, { params })
      .pipe(map((response) => response.data));
  }

  getLicenseTemplate(
    licenseTemplate: string
  ): Observable<LicenseTemplateComplete> {
    let params = new HttpParams();

    return this.http
      .get<ResponseDto<LicenseTemplateComplete>>(`/template-license/`+ licenseTemplate, { params })
      .pipe(map((response) => response.data));
  }

  createAdmin(
    payload: CreateAdminPayload
  ): Observable<IUser> {
    return this.http
      .post<ResponseDto<IUser>>(`/management-users`, payload)
      .pipe(map((response) => response.data));
  }
}
